
html{
    background-color: #FFFFFF;
}

.landingPageWrapper{
    background-color: #FFFFFF;
    height: 100%;
    width: 100%;
    font-family: sans-serif;
}

.landingPageWrapper > header {
    background-color: rgb(224, 235, 255);
}

.landingImageCopy {
    width: 90%;
    max-width: 600px;
    position: relative;
    background-color: rgb(224, 235, 255);
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
    box-shadow: 1px 1px 2px 1px rgb(149, 162, 185);
}

.landingImageCopy::after {
    content: "";
    clear: both;
    display: table;
  }

.landingImageCopy > img {
    float: right;
    width: 68%;
    padding: 5px;
    background-color: rgb(224, 235, 255);
}

.landingInformation {
    padding-right: 10px;
}

.landingInformation > p {
    font-size: 24px;
}

@media only screen and (max-width: 500px){
    /*for mobile devices*/
    .landingInformation > p {
        font-size: 18px;
    }
    .landingImageCopy {
        height: 550px;
    }
    .landingImageCopy > img {
        width: 60%;
    }
}