.selectDate{
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 5px;
    font-size: 16px;
    margin-top: 5px;
}

.selectDateContainer{
    display: inline-block;
    margin: 25px;
}