.ViewContainer{
    display: inline-block;   
}

.viewButtonContainer{
    display: inline-block;
    margin: 25px;
}

.viewButton {
    position: relative;
    display: block;
    font-size: 16px;
    padding: 5px;
    margin-top: 5px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 5px;
}


@media only screen and (max-width: 390px) {
    .viewButtonContainer {
        margin-bottom: 5px;
        margin-top: 0px;
    }
    
  }