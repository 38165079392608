

.Journal {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  border: black, solid, 5px;
  font-family: sans-serif;
}

.userInputs {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 5px;
  font-family: sans-serif;
}

@media only screen and (max-width: 500px) {
  .userInputs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

}