.form-warning, .form-error{
    background-color: black;
    font-weight: bold;
    color: white;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 5px 5px 10px 1px #10101A;
    border-radius: 5px;
}
