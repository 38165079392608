.registerContainer {
    position: absolute;
    display: inline-block;
    background-color: rgb(224, 235, 255);;
    text-align: center;
    width: 40%;
    right: 0px;
    margin-bottom: 5px;
    box-shadow: 1px 1px 1px 1px #10101A;
    padding: 5px;
}

input {
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    margin-top: 5%;
    margin-bottom: 5%;
}

button{
    margin-bottom: 5%;
}

@media only screen and (max-width: 500px) {
    .registerContainer {
      order: 2;
      position: relative;
      width: 240px;
      margin-left: auto;
      margin-right: auto;
    }
}