

.Journal {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  border: black, solid, 5px;
  font-family: sans-serif;
}

.userInputs {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 5px;
  font-family: sans-serif;
}

@media only screen and (max-width: 500px) {
  .userInputs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

}
header {
    position: relative;
    top: 10px;
    height: 20%;
    padding-top: 10px;
    background-color: rgb(224, 235, 255);
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 2px 1px rgb(149, 162, 185);
    border-radius: 5px;
    padding-bottom: 10px;
  }
.imgContainer {
    display: block;
    margin: 15px auto;
    padding: 15px;
    background-color: rgb(224, 235, 255);
    width: 85%;
    height: 425px;
    box-shadow: 1px 1px 1px 1px rgb(149, 162, 185);
    text-align: center;
    border-radius: 5px;
}

.tutorialImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    max-height: 300px;
    object-fit: cover;
    border: 5px solid black;
    border-radius: 5px;
}

.imgContainer > h3 {
    position: relative;
    display: inline-block;
    background-color: #FFFFFF;
    padding: 3px;
    border: black solid 1px;
}

@media only screen and (max-width: 500px){
    .tutorialImage{
        max-width: 70%;
        max-height: 50%;
    }    

}
.loginContainer {
    display: inline-block;
    background-color: rgb(224, 235, 255);;
    text-align: center;
    width: 40%;
    margin-bottom: 15px;
    box-shadow: 1px 1px 1px 1px #10101A;
    padding: 5px;
}
input {
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    margin-top: 5%;
    margin-bottom: 5%;
}

legend {
    margin-left: auto;
    margin-right: auto;
}

button{
    margin-bottom: 5%;
}

@media only screen and (max-width: 500px) {
    .loginContainer {
      -webkit-order: 1;
              order: 1;
      position: relative;
      width: 240px;
      margin-left: auto;
      margin-right: auto;
    }
}
.form-warning, .form-error{
    background-color: black;
    font-weight: bold;
    color: white;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 5px 5px 10px 1px #10101A;
    border-radius: 5px;
}

.registerContainer {
    position: absolute;
    display: inline-block;
    background-color: rgb(224, 235, 255);;
    text-align: center;
    width: 40%;
    right: 0px;
    margin-bottom: 5px;
    box-shadow: 1px 1px 1px 1px #10101A;
    padding: 5px;
}

input {
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    margin-top: 5%;
    margin-bottom: 5%;
}

button{
    margin-bottom: 5%;
}

@media only screen and (max-width: 500px) {
    .registerContainer {
      -webkit-order: 2;
              order: 2;
      position: relative;
      width: 240px;
      margin-left: auto;
      margin-right: auto;
    }
}


.UserHome {
    background-color: #FFFFFF;
    text-align: center;
    padding: 10px;
    font-family: sans-serif;
}

.dateViewContainer {
    display: inline-block;
    width: 95%;
    max-width: 660px;
    background-color: rgb(224, 235, 255);
    box-shadow: 1px 1px 1px 1px rgb(149, 162, 185);
}

.dateViewContainer > h1 {
    background-color: #FFFFFF;
    box-sizing: border-box;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    border-radius: 5px;
}

.ImageWrapperContainer{
    display: block;
    margin: 15px auto;
    padding: 15px;
    background-color: rgb(224, 235, 255);
    max-width: 550px;
    width: 85%;
    box-shadow: 1px 1px 1px 1px rgb(149, 162, 185);

}

/* .frontImage, .backImage{
    position: relative;
    background-color: black;
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 10px;
} */

.errorMessage{
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    background-color: #10101A;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF;
    font-size: 20px;
    box-shadow: 5px 5px 5px 1px rgb(149, 162, 185);
}

.frontImageWrapper, .backImageWrapper{
    margin-left: auto;
    margin-right: auto;
    width: 248px;
    box-shadow: 1px 1px 1px 1px rgb(149, 162, 185);
}

img {
    position: relative;
    display: block;
    margin-right: auto;
    margin-left: auto;
    background-color: black;
}

.ratePainWrapper {
    background-color: #FFFFFF;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    box-shadow: 1px 1px 1px 1px rgb(149, 162, 185);
}

.ratePainWrapper button {
    margin: 1.25%;
    height: 40px;
    width: 40px;
}

@media only screen and (max-width: 390px) {
  .dateViewContainer > h1 {
      margin-bottom: 5px;
  }
  
}
.selectDate{
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 5px;
    font-size: 16px;
    margin-top: 5px;
}

.selectDateContainer{
    display: inline-block;
    margin: 25px;
}
.logoutWrapper button {
    font-size: 20px;
    margin: 1%;
    width: 100px;
}
.ViewContainer{
    display: inline-block;   
}

.viewButtonContainer{
    display: inline-block;
    margin: 25px;
}

.viewButton {
    position: relative;
    display: block;
    font-size: 16px;
    padding: 5px;
    margin-top: 5px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 5px;
}


@media only screen and (max-width: 390px) {
    .viewButtonContainer {
        margin-bottom: 5px;
        margin-top: 0px;
    }
    
  }
.messageContainer {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    background-color: #10101A;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF;
    font-size: 24px
}


html{
    background-color: #FFFFFF;
}

.landingPageWrapper{
    background-color: #FFFFFF;
    height: 100%;
    width: 100%;
    font-family: sans-serif;
}

.landingPageWrapper > header {
    background-color: rgb(224, 235, 255);
}

.landingImageCopy {
    width: 90%;
    max-width: 600px;
    position: relative;
    background-color: rgb(224, 235, 255);
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
    box-shadow: 1px 1px 2px 1px rgb(149, 162, 185);
}

.landingImageCopy::after {
    content: "";
    clear: both;
    display: table;
  }

.landingImageCopy > img {
    float: right;
    width: 68%;
    padding: 5px;
    background-color: rgb(224, 235, 255);
}

.landingInformation {
    padding-right: 10px;
}

.landingInformation > p {
    font-size: 24px;
}

@media only screen and (max-width: 500px){
    /*for mobile devices*/
    .landingInformation > p {
        font-size: 18px;
    }
    .landingImageCopy {
        height: 550px;
    }
    .landingImageCopy > img {
        width: 60%;
    }
}
