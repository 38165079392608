.imgContainer {
    display: block;
    margin: 15px auto;
    padding: 15px;
    background-color: rgb(224, 235, 255);
    width: 85%;
    height: 425px;
    box-shadow: 1px 1px 1px 1px rgb(149, 162, 185);
    text-align: center;
    border-radius: 5px;
}

.tutorialImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    max-height: 300px;
    object-fit: cover;
    border: 5px solid black;
    border-radius: 5px;
}

.imgContainer > h3 {
    position: relative;
    display: inline-block;
    background-color: #FFFFFF;
    padding: 3px;
    border: black solid 1px;
}

@media only screen and (max-width: 500px){
    .tutorialImage{
        max-width: 70%;
        max-height: 50%;
    }    

}