

.UserHome {
    background-color: #FFFFFF;
    text-align: center;
    padding: 10px;
    font-family: sans-serif;
}

.dateViewContainer {
    display: inline-block;
    width: 95%;
    max-width: 660px;
    background-color: rgb(224, 235, 255);
    box-shadow: 1px 1px 1px 1px rgb(149, 162, 185);
}

.dateViewContainer > h1 {
    background-color: #FFFFFF;
    box-sizing: border-box;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    border-radius: 5px;
}

.ImageWrapperContainer{
    display: block;
    margin: 15px auto;
    padding: 15px;
    background-color: rgb(224, 235, 255);
    max-width: 550px;
    width: 85%;
    box-shadow: 1px 1px 1px 1px rgb(149, 162, 185);

}

/* .frontImage, .backImage{
    position: relative;
    background-color: black;
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 10px;
} */

.errorMessage{
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    background-color: #10101A;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF;
    font-size: 20px;
    box-shadow: 5px 5px 5px 1px rgb(149, 162, 185);
}

.frontImageWrapper, .backImageWrapper{
    margin-left: auto;
    margin-right: auto;
    width: 248px;
    box-shadow: 1px 1px 1px 1px rgb(149, 162, 185);
}

img {
    position: relative;
    display: block;
    margin-right: auto;
    margin-left: auto;
    background-color: black;
}

.ratePainWrapper {
    background-color: #FFFFFF;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    box-shadow: 1px 1px 1px 1px rgb(149, 162, 185);
}

.ratePainWrapper button {
    margin: 1.25%;
    height: 40px;
    width: 40px;
}

@media only screen and (max-width: 390px) {
  .dateViewContainer > h1 {
      margin-bottom: 5px;
  }
  
}