.messageContainer {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    background-color: #10101A;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    color: #FFFFFF;
    font-size: 24px
}
